<template>

  <div class="container mx-auto pt-16">
    <div class="w-11/12 xl:w-full mx-auto">
      <div class="pb-12">
        <h1 class="xl:text-5xl md:text-3xl text-2xl font-extrabold text-gray-800">Privacy Policy</h1>
        <p class="mt-5 text-gray-900 mx-2 leading-7">
          Sample Games, inc. and its affiliated entities (collectively, 'Sample Games’ or 'we') respects the privacy rights of our online users and is committed to protecting the personal information collected about or provided by you. We have adopted this Online Privacy Policy ('Privacy Policy') to guide how we store, collect, manage and use the personal information you provided in connection with the Service.

          Please note that this Privacy Policy applies only to information submitted and collected by Sample Games through use of the App & Web Services on the following platforms(collectively, ‘platforms’ or ‘Serviced Platforms’): Facebook Web/Canvas Games, Facebook Pages, Apple iOS App Store, Google Play Store, Amazon App Store. This Privacy Policy does not apply to information that may be collected offline. Moreover, this Privacy Policy applies to the Service, but not to websites maintained by other companies or organizations to which we may provide links. When you link to these websites, this Privacy Policy no longer applies. We are therefore not responsible for the content or activities provided or created on such sites.

          By using and accessing the Service provided by Sample Games, you signify your acceptance to our Online Privacy Policy. If you do not agree to this Privacy Policy, please do not use the Service. We reserve the right to make changes to this Privacy Policy. If we make a material change to this Privacy Policy, we will notify you by posting the change in this Privacy Policy and, if necessary, give you additional choices regarding such change. Please check back from time to time to ensure that you are aware of these changes. Your continued use of the Service will signify your acceptance of these changes.
        </p>
      </div>
      <div>
        <div class="mx-auto shadow">
          <div v-for="(entity, idx) in tos" class="xl:px-8 lg:px-8 md:px-8 sm:px-8 px-4" :key="`tos${idx}`">
            <div>
              <div class="flex py-6 justify-between items-center border-b border-gray-200">
                <h1 class="text-base md:text-xl lg:text-2xl font-bold">{{ idx + 1 }}. {{ entity.title }}</h1>
<!--                <div data-menu class="cursor-pointer" @click="entity.isOpen = !entity.isOpen">
                  <svg v-if="entity.isOpen" xmlns="http://www.w3.org/2000/svg" aria-label="Hide" class="icon icon-tabler icon-tabler-chevron-up" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#718096" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="6 15 12 9 18 15" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" aria-label="Show" class="icon icon-tabler icon-tabler-chevron-down" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#718096" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"></path>
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </div>-->
              </div>
              <ul>
                <li class="py-4 md:py-6 lg:py-8">
                  <p class="xl:w-10/12 lg:w-10/12 md:w-10/12 sm:w-10/12 w-full text-gray-600 text-base leading-7" v-html="entity.desc">
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 pb-12">
        <h1 class="xl:text-3xl md:text-2xl text-xl font-extrabold text-gray-800">Passive Means of Information Collection (Cookies, IP addresses, etc.)</h1>
      </div>
      <div>
        <div class="mx-auto shadow">
          <div v-for="(entity, idx) in pm" class="xl:px-8 lg:px-8 md:px-8 sm:px-8 px-4" :key="`pm${idx}`">
            <div>
              <div class="flex py-6 justify-between items-center border-b border-gray-200">
                <h1 class="text-base md:text-xl lg:text-2xl font-bold">{{ idx + 1 }}. {{ entity.title }}</h1>
              </div>
              <ul>
                <li class="py-4 md:py-6 lg:py-8">
                  <p class="xl:w-10/12 lg:w-10/12 md:w-10/12 sm:w-10/12 w-full text-gray-600 text-base leading-7" v-html="entity.desc">
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 pb-12">
        <h1 class="xl:text-3xl md:text-2xl text-xl font-extrabold text-gray-800">Privacy Rights</h1>
      </div>
      <div>
        <div class="mx-auto shadow">
          <div v-for="(entity, idx) in pr" class="xl:px-8 lg:px-8 md:px-8 sm:px-8 px-4" :key="`pr${idx}`">
            <div>
              <div class="flex py-6 justify-between items-center border-b border-gray-200">
                <h1 class="text-base md:text-xl lg:text-2xl font-bold">{{ idx + 1 }}. {{ entity.title }}</h1>
              </div>
              <ul>
                <li class="py-4 md:py-6 lg:py-8">
                  <p class="xl:w-10/12 lg:w-10/12 md:w-10/12 sm:w-10/12 w-full text-gray-600 text-base leading-7" v-html="entity.desc">
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue'

const tos = [
  {
    title: 'Information Collected',
    desc: `
      In order to give you the best experience in use of the Service, Sample Games may collect information about you from your account on serviced platforms, but only if you voluntarily provide it to us, in accordance with the policies and terms of the serviced platforms. Additionally, we may obtain information from you through your access and participation in message boards on the Service; your email of a question to our customer service desk; or your purchase of a virtual product of any type. Further, we may ask you for personal information (such as name or email address) if you choose to sign up for any particular Service we offer (such as a newsletter or reminder service). In all such cases, the information provided will not be disclosed to third parties and will be used solely for the purpose of providing you with the Service.<br><br>
      The types of personal information collected in conjunction with the activities listed above will vary depending on the activity. The requested information may include: (i) your name, (ii) mailing address, (iii) telephone number, (iv) email address, and, for those purchasing products or services online, credit card and billing information; and (v) information about the identity of your friends.<br><br>
      For some activities, Sample Games may also ask you to provide other non-personal information such as: (i) your age or date of birth; (ii) your gender; (iii) your game and platform preferences; (iv) information about the hardware you may own and/or games you may have recently purchased; (v) your favorite quotes, websites, friend information and fan pages; and, (vi) other game-related information (collectively referred to as 'demographic information'). If demographic information is collected for an activity that also requires personal information, Sample Games may combine your demographic information with your personal information.<br><br>
      You may provide us with another person’s email address to invite that person to participate in the Service. Sample Games may but is not obligated to use this information to contact the person to join the Service and in all events will do so only with your express permission.
    `,
  },
  {
    title: 'Use of Information collected',
    desc: `
      Sample Games will only use the personal information collected from you or from the serviced platforms for the operation of the Service in accordance with the policies and terms of the serviced platforms. Sample Games does not rent, lease, loan, sell, voluntarily distribute or redistribute your personal information to any unaffiliated third parties.<br><br>
      Sample Games may use your email address or other personal information to send you a newsletter, marketing or advertising materials, or service announcements that tell you about updates to our Privacy Policy, changes in pricing, changes to our online service or customer service policies, and other related service changes. Sample Games will not voluntarily disclose this personal information to unaffiliated third parties for any other purpose. Information collected either directly from you or from the Serviced Platforms is solely used in the operation of the Service. For example, personal and/or demographic information collected through one of the activities set forth above may be used for our internal demographic studies, so we can constantly improve the online products and services we provide you and better meet your needs. Additionally, we may use information that we collected to ensure that someone who is cheating is excluded, and to enforce our rights under our Terms of Service.<br><br>
      No matter what method we use to collect information and no matter how we use that information, we will only collect the information deemed reasonably necessary to fulfill your online requests and our legitimate business objectives, and such information will not be provided to any third parties.<br><br>
      Consistent with Sample Games’ Copyright and Intellectual Property Policy and in its sole discretion, we reserve the right to remove any of your personal information or content from the Service if we receive a notice of intellectual property infringement or other complaint.
    `,
  },
  {
    title: 'Warning Note about Message Boards',
    desc: `Note that whenever you give out personal information in public areas such as on our message board, that information can be collected and used by people you do not know. While we strive to protect those who wish to participate in these activities, we cannot guarantee the security and privacy of any information that you post in these areas and you acknowledge this fact.`,
  },
  {
    title: 'Other Disclosure of Your Personal Information',
    desc: `
      Notwithstanding any other terms of this Privacy Policy, we may disclose personal information in the good faith belief that we are required to do so by law, including but not limited to disclosure to law enforcement or other government officials in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose you or us to legal liability.<br><br>
      In addition, in the event of a merger, acquisition, reorganization, bankruptcy, or other similar event, customer information may be transferred to Sample Games’ successor or assign, if permitted by and done in accordance with applicable law.<br><br>
      We may also share aggregate information about our visitors to our Service. For example, we may say a particular game title's audience is X% female and Y% male. This data is used to customize the content and advertising of our Service to deliver better experience for our visitors. It can also help to tailor our products and services to meet the needs of our customers.<br><br>
      We may send to your web browser, directly or in directly, advertisements you see when you visit our Service. However, we may allow other companies, called third party ad servers, to serve advertisements within our Service. This Privacy Policy does not apply to, and cannot control the activities of, these other companies. If you want to prevent a third party ad server from sending or reading cookies on your computer, you must visit the company's web sites individually and opt-out.
    `,
  }
]

const pm = [
  {
    title: 'Cookies',
    desc: `We may use cookies in the operation of the Service. A 'cookie' is a small bit of record-keeping information that sites often store on a user's computer. Cookies are typically used to quickly identify a user's computer and to 'remember' things about the user's visit. For example, we may use cookies to keep track of your preferences and your username and password. Information contained in a cookie may be linked to your personal information for this purpose. You can disable cookies or set your browser to alert you when cookies are being sent to your computer, although this may affect your ability to use many of the features of the Service.`,
  },
  {
    title: 'IP Addresses',
    desc: `
      An Internet Protocol (IP) address is a number that is used by computers on the network to identify your computer every time you log on to the Internet. Although we do not generally collect IP addresses, we may collect and use your IP address when we feel it is necessary to protect our service, websites, customers, or others.<br><br>
      If you use the Service, we may collect other unique numbers associated with a computer's identity on a network.
    `,
  },
  {
    title: 'Log Files',
    desc: `
      Other information that may be passively collected by Sample Games is in the form of logs - files that record website activity, including how many 'hits' a particular web page is getting (a.k.a. 'navigational data'). These entries are generated anonymously, and enable us to gather statistics about users' browsing habits, assess overall website activity, track interest in advertised sales, and maintain website security. We also use the log file entries for our internal marketing and demographic studies, so we can constantly improve the online services we provide you. Log files are used internally only, and may be combined with data collected via cookies to help us aggregate behavior patterns of registered users.
    `,
  },
  {
    title: 'Privacy Policy and Third Party Web Sites, Links, Advertisements and Services',
    desc: `
      You should be aware that while you are using our Service, you may be directed to other sites that are beyond our control and for which we are not responsible. Additionally, third parties provide advertisements and offer other products and services to you when you are using our Service, which may receive your IP address or other technology such as cookies, web beacons and the like. By using the Service, you consent to such use by third parties of your personal information, which is subject to the privacy policies of these third parties.<br><br>
      You should consult the privacy policies of these third parties for more detailed information.
    `,
  }
]

const pr = [
  {
    title: 'Ask a Question about Privacy Policy',
    desc: `If you have any questions, complaints, or comments regarding this Privacy Policy or our information collection practices, please contact our Privacy Policy Administrator via email at samplegames.cs@gmail.com`,
  },
  {
    title: 'Our Policies Concerning Children',
    desc: `
      Our services are not directed at children under the age of 13 (or under the age of 16 for children in European countries). Children are not permitted to use these Services, and we do not knowingly collect any personal information from them. For games that are not directed at children but nonetheless may appeal to them, we take additional steps to limit access to these Services based on the user’s age.<br><br>
      If you are a child, please ask your parent(s) or guardian(s) for permission before using or playing on the Service. If you have not obtained permission from your parent(s) or guardian(s), you should not use our Service. No user under the age of 13 may provide any personal data. If we find out that we have collected personal data about a child under the age of 13, we will delete the data as quickly as possible.<br><br>
      If you are a parent or legal guardian of a child under the age of 13 (or under 16 in certain jurisdictions in the European Union) who uses our game, and if you also have any concerns regarding the service, or wish to review information collected from your child, or wish to have that information modified or deleted, you may contact us at samplegames.cs@gmail.com<br><br>
      This Privacy Policy was last updated on 23 Aug 2019.
    `,
  }
]

export default defineComponent({
  name: 'TermsOfService',
  setup() {
    return {
      tos,
      pm,
      pr
    }
  }
})
</script>
